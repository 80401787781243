.main-footer {
  display: none !important;
}

.ReactModal__Overlay {
  z-index: 10000;
}

.p {
  font-size: 12px;
  font-style: italic;
}
